import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

const Heading = (heading) => (
  <div className={`${heading.class} card-header`}>
    {heading.content.map((content) => {
      return content;
    })}
  </div>
);

const Body = (body) => (
  <div className={`${body.class} card-body`}>
    {body.content.map((content) => {
      return content;
    })}
  </div>
);

const Footer = (footer) => (
  <div className={`${footer.class} card-footer`}>
    {footer.content.map((content) => {
      return content;
    })}
  </div>
);

const checkSegment = (segment) => {
  if (segment.type === "card-header") {
    return <Heading {...segment} />;
  } else if (segment.type === "card-body") {
    return <Body {...segment} />;
  } else if (segment.type === "card-footer") {
    return <Footer {...segment} />;
  } else if (segment.type === "card-img-top") {
    return (
      <GatsbyImage
        image={segment.imgVariable}
        alt={segment.altText}
        className={`card-image-top border-radius-bottom-0 ${segment.class}`}
      />
    );
  } else {
    return segment;
  }
};

/**
 * SegmentsDataObject
 * @typedef {Object} SegmentsDataObject
 * @property {string} type - required string with the type of segment, accepted values "card-header", "card-body", "card-footer", "card-img-top", in case we don't define it we can render a whole react component instead of the object in the array like `<div>hello</div>`.
 * @property {string} class - optional string like "px-md-4".
 * @property {Object[]} content - Array with the content for the segment, renders in order react elements as elements of the array for example [`<div>hello</div>`].
 */

/**
 * This is the component to render the generic card.
 *
 * @param {Object} props - The object containing props.
 * @param {string} props.cardClass - Classes for div container.
 * @param {SegmentsDataObject[]} props.segments - Array of objects with the "segments" to render in the card.
 *   - `type`: required string with the type of segment, accepted values "card-header", "card-body", "card-footer", "card-img-top", in case we don't define it we can render a whole react component instead of the object in the array like `<div>hello</div>`.
 *   - `class`: optional string like "px-md-4".
 *   - `content`: Array with the content for the segment, renders in order react elements as elements of the array for example [`<div>hello</div>`].
 * @returns {React.JSX.Element} Returns the JSX component to render.
 */
const CardGeneric = ({
  cardClass = "border mt-4 mt-lg-0",
  segments = [
    {
      type: "card-body",
      class: "px-md-4",
      content: [
        <h5 className="card-title">Sign in title</h5>,
        <Button
          {...{
            text: "Button",
            showIcon: false,
            class: "btn-success btn-md btn-block no-max-width",
            style: {
              minWidth: "0"
            }
          }}
        />
      ]
    },
    {
      type: "card-footer",
      class: "bg-light",
      content: [
        <div className="row">
          <div className="col col-lg-9">Some footer text</div>
          <div className="col-auto col-lg-3 text-nowrap">Another text</div>
        </div>
      ]
    }
  ]
}) => {
  return (
    <div className={`card ${cardClass}`}>
      {segments.map((segment) => {
        return checkSegment(segment);
      })}
    </div>
  );
};

export default CardGeneric;

CardGeneric.defaultProps = {
  cardClass: PropTypes.string,
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      content: PropTypes.arrayOf(PropTypes.node).isRequired,
      class: PropTypes.string
    })
  )
};
